import React from 'react';
import { graphql } from 'gatsby';
import WebinarsPage from 'components/pages/WebinarsPage';

const seo = {
  title: 'Check out all the replays and upcoming Q°emotion webinars!',
  keywords: [],
  description:
    'Do you want to learn more about the emotional semantic analysis of your verbatim and customer reviews? Discover the webinars prepared by our experts! Subscribe to the next ones or watch our replay.',
};

export default props => <WebinarsPage {...props} seo={seo} />;

export const pageQuery = graphql`
  query {
    dataIntroBlockTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "webinars" }
        blockType: { eq: "introBlock" }
        component: { eq: "title" }
        lang: { eq: "en" }
      }
    ) {
      html
    }
    allImages: allFile(filter: { relativeDirectory: { eq: "bubbleImages" } }) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 550, quality: 100) {
            presentationWidth
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    allWebinars: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "webinars" }
          blockType: { eq: "webinarBlock" }
          lang: { eq: "en" }
        }
      }
      sort: { fields: [frontmatter___index], order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          id
          duration
        }
        html
      }
    }
    dataBlockLinkBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "clientCase" }
          blockType: { eq: "linkBlock" }
          lang: { eq: "en" }
        }
      }
      sort: { fields: [frontmatter___idBlock] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          subTitle
          color
          link
          buttonText
        }
      }
    }
  }
`;
